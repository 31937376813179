import { Box, Button, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/TimeLinkLogoBlackText.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from '../../redux/store/store';
import { getBusinessFeedbackInfo, markGoogleReview, submitFeedback } from '../../redux/slice/UserSlice';
import { decode } from 'base-64';
import { useSelector } from 'react-redux';
import close from "../../assets/images/close.png";
import goggle from "../../assets/images/GoggleG.png";
import GoggleReview from "../../assets/images/GoggleReview.png";
import Checkmark from "../../assets/images/checkmark.png";
import zIndex from '@mui/material/styles/zIndex';


const Visiting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { bk: businessKey, oid: orderId } = useParams();
  const token = localStorage.getItem('accessToken');
  if (!token) {
    localStorage.setItem('redirectAfterLogin', location.pathname + location.search);
    navigate('/loginOrSignup')
  }
  useEffect(() => {
    if (!token) {
      localStorage.setItem('redirectAfterLogin', location.pathname + location.search);
      navigate('/loginOrSignup')
    }
  }, [])
  const { isLoading } = useSelector((state) => state.user);
  const [previousFeedBackInfo, setPreviousFeedBackInfo] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [anonymous, setAnonymous] = useState(null);
  const reactionEmojies = ["😠", "😟", "😐", "🙂", "😀"];
  const queryParams = new URLSearchParams(location.search);
  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const [hasGivenAllModal, setHasGivenAllModal] = React.useState(false);
  const [openModalGoggleReview, setOpenModalGoggleReview] =
    React.useState(false);
  const [googleReviewLink, setGoogleReviewLink] = useState();

  // Example: Getting specific url params
  const bk = decode(decodeURIComponent(businessKey)); // Gets 'param1' from URL
  const oid = decode(decodeURIComponent(orderId)); // Gets 'param2' from URL

  useEffect(() => {
    dispatch(getBusinessFeedbackInfo(bk, oid, (data) => {
      if (!data) {
        navigate('/account', { replace: true })
      }
      setPreviousFeedBackInfo(data);
      if (data?.hasProvidedFeedback) {
        if (!data?.hasProvidedGoogleReview) {
          if (data?.business?.settings?.get_google_review != 1) {
            setHasGivenAllModal(true)
          } else {
            setOpenModalGoggleReview(true);
          }
        } else {
          setHasGivenAllModal(true)
        }
      }
      setGoogleReviewLink(data?.business?.settings?.google_review_link)
    }))
  }, [])
  function handleSubmitFeedback() {
    var user = JSON.parse(localStorage.getItem("userDetails"));
    if (selectedReaction == null || feedbackMessage.length < 0) {
      toast.error("Both reaction and message are required!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    var data = {
      reaction: reactionEmojies[selectedReaction],
      message: feedbackMessage,
      order_id: oid,
      user_id: user.id,
      business_id: bk,
      is_anonymous: 1//anonymous ? 1 : 0,
    };
    dispatch(submitFeedback(data, handleSubmitFeedbackclose));
  }

  function handleSubmitFeedbackclose(data) {
    toast.success("Great! Feedback is submited Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setFeedbackModal(false);
    handleOpenGoogleReview();
  }

  function handleOpenGoogleReview() {
    if (previousFeedBackInfo?.business?.settings?.get_google_review != 1) {
      navigate('/account', { replace: true })
    } else {
      if (!previousFeedBackInfo?.hasProvidedGoogleReview) {
        setOpenModalGoggleReview(true);
      }
    }
  }

  function handleGoogleReviewLinkClick() {
    dispatch(markGoogleReview(previousFeedBackInfo?.business?.id, (data) => false))
    navigate('/account', { replace: true })
  }

  const handleHome = () => {
    navigate("/account", { replace: true });
    setHasGivenAllModal(false);
  };

  const style = {
    position: "fixed", // Change position to fixed
    bottom: 0, // Align modal to the bottom
    left: "50%",
    transform: "translateX(-50%)", // Center horizontally
    bgcolor: "background.paper",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    maxHeight: "90vh", // Limit modal height to 90% of viewport height
    minHeight: "50vh",
    overflowY: "auto", // Add scroll when content overflows
  };

  const style2 = {
    position: "absolute",
    zIndex: 100,
    top: "0%",
    left: "0%",
    width: '100%',
    height: '100%',
    bgcolor: "white",
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {
            hasGivenAllModal &&
            <Box sx={style2} className="lato text-center flex justify-center items-center flex-column border-0">

              <div className='-mt-10'>
                <img style={{ width: 100, height: 100 }} src={Checkmark} alt="all given" />
              </div>
              <div className='my-3'>
                <h5>We appreciate your input! This feedback has already been completed.</h5>
              </div>


              <div className="flex-column d-flex gap-3 w-75 mx-auto absolute bottom-[6%]">
                <button
                  className="helvetica-medium fw-medium  p-3 rounded-5 fw-semibold text-blue"
                  type="button"
                  style={{ background: "#CCD7FF" }}
                  onClick={handleHome}
                >
                  Go Home
                </button>
              </div>
            </Box>
          }

          <div className="d-flex flex-column bg-white justify-content-between  " style={{ minHeight: "100vh" }}>
            <div className=' cursor-pointer' style={{ border: "1.5px solid #f2f2f2", borderRadius: "22px" }}

            >
              <div className='position-relative  ' style={{ height: "150px", borderTopLeftRadius: "22px", borderTopRightRadius: "22px" }}>

                <img src={previousFeedBackInfo?.business?.cover_image} className=' position-absolute top-0 start-0 w-100 object-fit-cover h-100' alt="w8" style={{ borderTopLeftRadius: "22px", borderTopRightRadius: "22px", height: 150 }} />
                <div className='z-3 position-relative  h-100 w-100 d-flex align-items-center justify-content-center ' style={{ background: 'rgba(255, 255, 255, 0.3)', borderTopLeftRadius: "22px", borderTopRightRadius: "22px" }}>
                  <div className='manrope text-center'>

                    <img className='mx-auto rounded-xl' src={previousFeedBackInfo?.business?.image ?? logo} width={180} alt="" />
                  </div>
                </div>
              </div>


            </div>
            {/* <div className="container text-center mt-4 justify-center items-center ">

              <img className='mx-auto' src={previousFeedBackInfo?.business?.image ?? logo} width={200} alt="" />
            </div> */}
            <div className="container flex-grow-1 d-flex align-items-center  ">
              <div className='w-100'>
                <div className=" ">
                  <h6
                    className="inter text-start fw-bold font-24 ps-3"
                  >
                    Hey there! Thanks for visiting {previousFeedBackInfo?.business?.business_name}
                  </h6>
                  <p
                    className="font-14  inter ps-3"
                    style={{ color: "#898989" }}
                  >Leave the store some feedback to let them know how they’re doing?</p>

                </div>
                <div className="p-3 pb-0 pt-0 container">
                  <div className="hyModal-textare">
                    <div className="d-flex flex-row justify-content-between">
                      {reactionEmojies.map((value, index) => {
                        return (
                          <span
                            onClick={() => {
                              setSelectedReaction(index);
                            }}
                            style={
                              selectedReaction == index
                                ? { backgroundColor: "#3461FD" }
                                : {}
                            }
                          >
                            {value}
                          </span>
                        );
                      })}
                    </div>
                    <textarea
                      rows="7"
                      className="form-control mt-4 font-14 "
                      placeholder="Tell us what you think..."
                      value={feedbackMessage}
                      onChange={(e) => {
                        setFeedbackMessage(e.target.value);
                      }}
                    ></textarea>
                    <p
                      className="font-12 mt-3 inter text-start"
                      style={{ color: "#D1D5DB" }}
                    >
                      Your input, our improvement, your privacy.
                    </p>
                  </div>
                  {/* <div className="d-flex flex-row align-content-center gap-2">
                    <input
                      type="checkbox"
                      checked={anonymous}
                      onChange={() => {
                        setAnonymous(!anonymous);
                      }}
                    />
                    <p
                      className="font-12 mt-3 inter text-start"
                      style={{ color: "#000" }}
                    >
                      Submit my feedback anonymously.
                    </p>
                  </div> */}
                  <div className="mt-3 row justify-content-center">
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-border p-3 py-2 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                        onClick={() => {
                          setFeedbackModal(false);
                          handleOpenGoogleReview();
                        }}
                      >
                        Skip
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                        onClick={() => {
                          handleSubmitFeedback();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={openModalGoggleReview}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableScrollLock={true}
            >
              <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
                <div className="">
                  <div className="pt-2 p-3 pb-1  text-center">
                    <h5 className="inter fw-semibold">Thank you for that!</h5>
                    <Button
                      onClick={() => {
                        setOpenModalGoggleReview(false);
                        navigate("/account");
                      }}
                      className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                      variant="text"
                    >
                      <img src={close} width={24} height={24} alt="w8" />
                    </Button>
                  </div>

                  <div className="p-3 pb-0 pt-0 container ">
                    <div className="row justify-content-center ">
                      <div className="col-8 my-4">
                        <img src={GoggleReview} className="w-100" alt="w8" />
                      </div>
                      <p
                        className="font-13 mb-4 text-center inter"
                        style={{ color: "#5B5B5B" }}
                      >
                        Your feedback goes a long way! How about <br /> a google
                        review?
                      </p>
                    </div>

                    <div className="mt-3 row justify-content-center mb-4">
                      <div className="col-12">
                        <a
                          href={googleReviewLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleGoogleReviewLinkClick}
                        >
                          <Button
                            type="button"
                            className="btn-border px-2 py-2 rounded-3 justify-content-between w-100 align-items-center text-white mx-auto font-14-69 montserrat"
                          >
                            <img
                              src={goggle}
                              alt="w8"
                              width={50}
                              height={50}
                              className="h-100 px-1"
                            />
                            <p className="mb-0 font-14 inter">Google Review</p>
                            <img
                              src={goggle}
                              alt="w8"
                              width={50}
                              height={50}
                              className="px-1"
                              style={{ visibility: "hidden" }}
                            />
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

          </div>
        </>
      )
      }
      <ToastContainer />
    </>
  )
}

export default Visiting
